import { useState, useEffect } from "react";
import ComingSoon from "../comingSoon/ComingSoon";
import Footer from "../layout/Footer"
import Header from "../layout/Header";
import Hero from "../layout/Hero";
import AboutUs from "./About";
import Services from "./Services";
import SplittingLine from "../layout/SplittingLine";
import ContactUs from "./contact";
import ContactInfo from "./ContactInfo";
import OurClients from "./OurClients";
import RecentWork from "./RecentWork";
import ChatBot from "../layout/ChatBot";
import Loader from "../layout/Loader";
import useSWR from "swr";
import { useLoading } from "../../contexts/LoaderContext";
import VideoHeroSection from "../layout/VideoHeroSection";

function HomePage() {
    const fetcher = (...args) => fetch(...args).then(res => res.json())
    const { data, error, isLoading } = useSWR('https://mvm.art/api/home-page-details/', fetcher)
    const { setIsLoading } = useLoading()
    const [homePageData, setHomePageData] = useState({
        about_us: "",
        about_us_mobile: "",
        contact_us: "",
        contact_us_mobile: "",
        get_in_touch: "",
        get_in_touch_mobile: "",
        address_link: "", 
        phone: "",
        whatsapp: "",
        is_video_hero_section: null,
        hero_video: "",
        hero_video_mobile: "",
        advertisement_image1: "",
        advertisement_image2: "",
        advertisement_image3: "",
        production_image1: "",
        production_image2: "",
        production_image3: "",
        post_production_image1: "",
        post_production_image2: "",
        post_production_image3: "",
        visual_content_image1: "",
        visual_content_image2: "",
        visual_content_image3: ""
    })
    
    useEffect(() => {
        setIsLoading(isLoading);
        if (data) {
            console.log("this is data: ", data)
            const firstObj = data[0]
            setHomePageData(firstObj)
        }
    }, [data, error, isLoading]);

    return (
        <>
            {/* <ComingSoon /> */}
            <Header />
            {homePageData?.is_video_hero_section && homePageData?.hero_video && homePageData?.hero_video_mobile ? (
                <VideoHeroSection webVideo={homePageData.hero_video} mobileVideo={homePageData.hero_video_mobile} />
            ): (
                <Hero currentPage={'home'} />
            )}
            <SplittingLine />
            <Services 
                advertisementImages={[homePageData?.advertising_image1, homePageData?.advertising_image2, homePageData?.advertising_image3]}
                productionImages={[homePageData?.production_image1, homePageData?.production_image2, homePageData?.production_image3]}
                postProductionImages={[homePageData?.post_production_image1, homePageData?.post_production_image2, homePageData?.post_production_image3]}
                visualContentImages={[homePageData?.visual_content_image1, homePageData?.visual_content_image2, homePageData?.visual_content_image3]}
            />
            <AboutUs aboutUsWebImage={homePageData?.about_us} aboutUsMobileImage={homePageData?.about_us_mobile} />
            <RecentWork currentPage={'home'} />
            <OurClients />
            <ContactUs contactUsWebImage={homePageData?.contact_us} contactUsMobileImage={homePageData?.contact_us_mobile}/>
            <ContactInfo contactInfoData={{bg: homePageData?.get_in_touch, bg_mobile: homePageData?.get_in_touch_mobile, address_link: homePageData?.address_link, phone: homePageData?.phone, whatsapp: homePageData?.whatsapp}}/>
            <Footer />
            {/* <df-messenger
            intent="WELCOME"
            chat-title="MVM"
            agent-id="1ba959cb-10b7-4aa0-8ba1-5d7b96aca6fd"
            language-code="en"
            ></df-messenger> */}
            <ChatBot />
        </>
    )



} 

export default HomePage;