import '../../assets/css/clients.css';
import useSWR from 'swr';
import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination } from 'swiper/modules';

import logo1 from '../../assets/images/logo1.png';
import logo2 from '../../assets/images/logo2.png';
import logo3 from '../../assets/images/logo4.png';
import { logDOM } from '@testing-library/react';

function OurClients() {
    const fetcher = (...args) => fetch(...args).then(res => res.json())
    const { data, error, isLoading } = useSWR(`https://mvm.art/api/our-client-list/`, fetcher)
    const [sliderImages, setSliderImages] = useState([
        logo2,
        logo1,
        logo3,
        logo2,
        logo1,
        logo3,
    ]);

    useEffect(() => {
        if (data) {
            if (data.length > 0) {
                let tempSliderImages = []
                data.forEach((obj) => {
                    tempSliderImages.push(obj.image)
                })
                const duplicatedTempSliderImages = [...tempSliderImages, ...tempSliderImages]
                setSliderImages(duplicatedTempSliderImages)
            }
        }
    }, [data, error, isLoading]);
    
    return (
        <>
            <div className="clients-slider-section" style={{ backgroundColor: '#1c1c1c'}}>
            <div className="title-section text-center">
                <div className="line mb-4 mt-5"></div>
                <h2 className="section-title mb-2">Our Clients</h2>
                <div className='section-description d-flex justify-content-center'>
                    <p className="text-center services-section-description">
                        We are proud to partner with an incredible  clients across various industries. Together, we achieve great milestones and foster long-lasting relationships. Thank you for being a part of our journey.
                    </p>
                </div>
            </div>
            {/* <div className="clients-slider mt-2 mb-5">
                <div className="clients-slider-items">
                    {sliderImages.map((clientLogo, index) => {
                        return (
                            <img src={clientLogo} key={index} alt='partner-logo' style={{ width: 'auto', height: '100px'}} />
                        )
                    })}
                </div>
            </div> */}
            </div>

            <Swiper
                autoplay={{
                    delay: 0, // Continuous scrolling without delay
                    disableOnInteraction: false, // Keeps autoplay even when interacting
                }}
                speed={5000} // Slow down the transition duration
                spaceBetween={-250}
                slidesPerView={3}
                centeredSlides={false}
                pagination={{
                    clickable: true
                }}
                modules={[Autoplay]}
                loop={true}
                className="recent-work-slider"
                breakpoints={{
                    0: {
                        slidesPerView: 2, // This will show a little bit of the next slide
                        spaceBetween: -25
                    },
                    768: {
                        slidesPerView: 3
                    }
                }}
                style={{ maxHeight: '200px', backgroundColor: '#1c1c1c' }}
            >
                {sliderImages.map((myImg, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <div className='mydiv d-flex justify-content-center' style={{ width: '125px', height: '125px', position: 'relative', overflow: 'hidden' }}>
                                <img src={myImg} alt="hi"     style={{
                                    height: '100%', // Fills the height of the parent 150 * 150 on wide screens
                                    width: '100%', // Fills the width of the parent
                                    objectFit: 'contain', // Ensures the image fits inside while maintaining aspect ratio
                                }}/>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </>
    );
}

export default OurClients;